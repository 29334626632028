import { isNumber, isString, toNumber } from 'lodash';
import { APPLY_TO_ALL_BRANDS } from '@/utils/constants';

export default class CreatePOSMCategoryItem {
  /**
   * @param {{brandId: number}} payload
   * @param {String} payload.name
   * @param {String} payload.size
   * @param {Number} payload.priority
   * @param {Number} payload.brandId
   * @param {String} payload.position
   */
  constructor(payload) {
    this.name = payload.name && isString(payload.name) ? payload.name : '';
    this.size = payload.size && isString(payload.size) ? payload.size : '';
    this.priority =
      payload.priority &&
      isNumber(toNumber(payload.priority)) &&
      !isNaN(toNumber(payload.priority))
        ? toNumber(payload.priority)
        : null;
    this.brandId =
      payload.brandId &&
      isNumber(toNumber(payload.brandId)) &&
      !isNaN(toNumber(payload.brandId))
        ? toNumber(payload.brandId)
        : APPLY_TO_ALL_BRANDS;
    this.position =
      payload.position && isString(payload.position) ? payload.position : '';
  }
}
